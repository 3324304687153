<template>
  <b-row>
    <b-col cols="12">
      <order-products-table
          :order-id="orderId" />
    </b-col>
  </b-row>
</template>

<script>
import router from '@/router'
import { BRow, BCol } from 'bootstrap-vue'
import OrderProductsTable from './OrderProductsTable.vue'

export default {
  components: {
    BRow,
    BCol,

    OrderProductsTable
  },
  data() {
    return {
      orderId: null,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
    console.log(this.orderId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>